const _ = require("lodash");

export function getStatus({ field }) {
  if (_.isEmpty(field) || !_.isString(field)) return { status: "Default" };

  if (field.includes("core")) {
    return { status: "core", type: "is-primary" };
  } else if (field.includes("server")) {
    return { status: "server" };
  } else if (field.includes("plugin")) {
    return { status: "plugin", type: "is-info" };
  } else if (field.includes("theme")) {
    return { status: "theme" };
  } else {
    return { status: "debug" };
  }
}

export function mapSnapshot({ data, timestamp, changeId, siteId, skip = [] }) {
  let result = [];

  _.keys(data).forEach(key => {
    if (skip.find(i => _.startsWith(key, i))) {
      return;
    }

    const isAdded = _.endsWith(key, "__added");
    const isDeleted = _.endsWith(key, "__deleted");

    _.keys(_.get(data[key], "fields")).forEach(field => {
      const category = isAdded
        ? _.replace(key, "__added", "")
        : isDeleted
        ? _.replace(key, "__deleted", "")
        : key;

      const keyTrimmed = _.replace(
        _.replace(key, "__added", ""),
        "__deleted",
        ""
      );

      const fieldTrimmed = _.replace(
        _.replace(field, "__added", ""),
        "__deleted",
        ""
      );

      result.push({
        status: getStatus({ field: category }),
        timestamp,
        changeId,
        siteId,
        category,
        categoryLabel: _.get(data, `${key}.label`) || category,
        field,
        label:
          _.get(data, `${key}.fields.${field}.label`) ||
          _.get(data, `${key}.fields.${field}.${fieldTrimmed}.label`) ||
          fieldTrimmed,
        new: getObjValue(
          data,
          [keyTrimmed, "fields", fieldTrimmed, "value"],
          ["__added"],
          true
        ),
        old: getObjValue(
          data,
          [keyTrimmed, "fields", fieldTrimmed, "value"],
          ["__deleted"],
          false
        )
      });
    });
  });

  return result;
}

export function mapChanges(changes = []) {
  let result = {};
  _.forEach(
    changes.filter(i => !i.initialState),
    change => {
      _.keys(change.diff).forEach(key => {
        if (
          _.startsWith(key, "wp-dropins") ||
          _.startsWith(key, "wp-paths-sizes")
        ) {
          return;
        }

        result[change._id] = mapSnapshot({
          data: change.diff,
          siteId: change.siteRef.id,
          changeId: change._id,
          timestamp: change.timestamp,
          skip: ["wp-dropins", "wp-paths-sizes"]
        });
      });
    }
  );

  return result;
}

const getValueOrNewOld = function(value, getNew = true) {
  return _.isObject(value) && _.has(value, "__new") && _.has(value, "__old")
    ? _.get(value, getNew ? "__new" : "__old")
    : value;
};

export function getObjValue(obj, paths = [], suffixes = [], getNew = true) {
  if (_.get(obj, paths.join(".")))
    return getValueOrNewOld(_.get(obj, paths.join(".")), getNew);

  for (let j = 0; j < suffixes.length; j++) {
    const suffix = suffixes[j];

    for (let index = 0; index < paths.length; index++) {
      const path = paths[index];
      const p = `${path}${suffix}`;
      const arr = [...paths];
      arr[index] = p;

      if (_.get(obj, arr.join("."))) {
        const val = _.get(obj, arr.join("."));
        return getValueOrNewOld(val, getNew);
      }
    }
  }
  return "";
}
